<template>
  <div class="editableCell" :style="{ width: width }">
    <!-- 普通文本/打分 -->
    <el-input
      class="rowheight"
      v-model="editValue"
      v-if="
        inputType === 'text' ||
        inputType === 'input' ||
        inputType === 'rate' ||
        fieldName === 'zongjia' ||
        fieldName === 'totalamount' ||
        fieldName === 'totalprice' ||
        fieldName === 'total'
      "
      @keyup.enter.native="searchEnterBtn"
      :placeholder="placeholder"
      :disabled="
        disabled ||
        fieldName === 'xiaoji' ||
        fieldName === 'zongjia' ||
        fieldName === 'amount' ||
        fieldName === 'totalprice' ||
        fieldName === 'totalamount' ||
        fieldName === 'total'
      "
    >
      <div
        slot="prefix"
        v-if="
          fieldName === 'xiaoji' ||
          fieldName === 'zongjia' ||
          fieldName === 'amount' ||
          fieldName === 'totalprice' ||
          fieldName === 'totalamount' ||
          fieldName === 'zongjia' ||
          fieldName === 'totalamount' ||
          fieldName === 'totalprice' ||
          fieldName === 'total'
        "
      >
        {{ this.$store.state.userInfoObj.currencyCode }}
      </div>
    </el-input>
    <!-- 过滤器中的地理定位字段 -->
    <div
      v-if="inputType === 'location' && originType === 'filter'"
      class="location"
    >
      <el-input> </el-input>
      <el-input> </el-input>
    </div>
    <div
      v-else-if="
        fieldName === 'xsjg' ||
        fieldName === 'unitprice' ||
        fieldName === 'rate'
      "
      class="cellInput rowheight"
    >
      <el-input
        v-model="editValue"
        :readonly="true"
        :precision="2"
      >
        <div slot="prefix" v-if="editValue || editValue === 0">
          {{ this.$store.state.userInfoObj.currencyCode }}
        </div>
        <i
          slot="suffix"
          class="el-input__icon el-icon-search xsjg"
          @click="serchTableShowMe"
        ></i>
      </el-input>
    </div>
    <!-- 折扣选择对象 -->
    <div
      v-else-if="
        fieldName === 'customerdiscount' ||
        fieldName === 'totalcustomer' ||
        fieldName === 'discountamount' ||
        fieldName === 'discount' ||
        fieldName === 'zhekou'
      "
      class="cellInput rowheight"
    >
      <el-input v-model="editValue" :readonly="true">
        <div slot="prefix">
          {{ this.$store.state.userInfoObj.currencyCode }}
        </div>
        <i
          slot="suffix"
          class="el-input__icon el-icon-edit"
          @click="switchShow(true)"
        ></i>
      </el-input>
      <el-card v-if="tipShow.zhekouFlg" class="cellCard">
        <div style="margin: 10px 0px">设置折扣</div>
        <div class="discountCardInput">
          <el-radio-group v-model="radioData.radio">
            <el-radio label="1"
              ><p>百分比</p>
              <el-input
                size="mini"
                style="width: 60px"
                v-show="radioData.radio === '1'"
                type="number"
                v-model="radioData.percentageValue"
              />
              <span
                v-show="radioData.radio === '1'"
                style="color: #303133; margin-left: 5px; font-size: 14px"
                >%</span
              >
            </el-radio>

            <el-radio label="2" style="margin: 10px 0px"
              ><p>直接减价</p>
              <el-input
                size="mini"
                style="width: 60px"
                v-show="radioData.radio === '2'"
                v-model="radioData.cutValue"
                type="number"
              />
            </el-radio>
          </el-radio-group>
        </div>

        <div class="btBox">
          <el-button type="primary" size="mini" @click="discoundSave(0)"
            >完成</el-button
          >
          <el-button size="mini" @click="switchShow(true)">取消</el-button>
        </div>
      </el-card>
    </div>
    <!-- 税率对象选择 -->
    <div
      v-else-if="fieldName === 'tax' || fieldName === 'taxamount'"
      class="cellInput"
    >
      <el-input v-model="editValue" :readonly="true" class="rowheight">
        <div slot="prefix">
          {{ this.$store.state.userInfoObj.currencyCode }}
        </div>
        <i
          slot="suffix"
          class="el-input__icon el-icon-edit"
          @click="switchShow(false)"
        ></i>
      </el-input>
      <el-card v-show="tipShow.taxFlg" class="cellCard">
        <div>选择税种</div>
        <div class="taxCheckBox" v-if="this.taxValue.taxCheckList.length > 0">
          <div
            v-for="tax in this.taxValue.taxCheckList"
            :key="tax"
            class="taxList"
          >
            <el-checkbox v-model="tax.checked">
              <div class="taxName">{{ tax.name }}</div>
            </el-checkbox>
            <el-input
              size="mini"
              style="width: 50px"
              v-model="tax.ratio"
              :disabled="true"
            />
            <span style="margin-left: 10px; color: #606266">%</span>
          </div>
        </div>
        <div v-else class="taxCheckBox">
          <p class="unLink">该产品未关联任何税种。</p>
        </div>
        <div class="btBox">
          <el-button type="primary" size="mini" @click="discoundSave('1')"
            >完成</el-button
          >
          <el-button size="mini" @click="switchShow(false)">取消</el-button>
        </div>
      </el-card>
    </div>
    <!-- 数字或百分数 -->
    <el-input-number
      clearable
      v-model="editValue"
      :min="min"
      :max="max"
      :controls="false"
      :precision="precision"
      v-else-if="
        (inputType === 'number' || inputType === 'percent-number') &&
        fieldName !== 'totalamount' &&
        fieldName !== 'totalprice' &&
        fieldName !== 'total' &&
        fieldName !== 'zongjia'
      "
      :disabled="
        disabled ||
        fieldName === 'xiaoji' ||
        fieldName === 'zongjia' ||
        fieldName === 'amount' ||
        fieldName === 'totalprice' ||
        fieldName === 'totalamount' ||
        fieldName === 'total'
      "
    >
      <div
        slot="prefix"
        v-if="
          (fieldName === 'xiaoji' ||
            fieldName === 'zongjia' ||
            fieldName === 'amount' ||
            fieldName === 'totalprice' ||
            fieldName === 'totalamount' ||
            fieldName === 'total') &&
          editValue
        "
      >
        {{ this.$store.state.userInfoObj.currencyCode }}
      </div>
    </el-input-number>
    <!-- 过滤器下的评分字段用数字输入框显示 -->
    <el-input-number
      clearable
      v-model="editValue"
      :min="min"
      :max="max"
      :controls="false"
      :precision="precision"
      v-if="inputType === 'score' && originType === 'filter'"
      :disabled="disabled"
    >
    </el-input-number>
    <!-- 评分 -->
    <div
      class="stars editStarts"
      :class="starsClassName"
      v-if="inputType === 'score' && originType === undefined"
    >
      <span
        v-for="(star, idx) in schemefieldLength + 1"
        v-show="idx !== 0"
        :class="{ show2: idx <= editValue }"
        @mouseover="setStar(idx)"
        @mouseout="setStar('active')"
        @click="setClick(idx)"
        :key="idx"
      >
      </span>
    </div>
    <!-- 文本区 -->
    <el-input
      v-model="editValue"
      type="textarea"
      :rows="rows"
      v-if="inputType === 'textarea'"
      :disabled="disabled"
    >
    </el-input>
    <!-- 日期 -->
    <el-date-picker
      v-model="editValue"
      :type="inputType"
      :placeholder="$t('label_tabpage_selectdate')"
      value-format="yyyy-MM-dd"
      :format="dateFormat"
      v-else-if="inputType === 'date' && originType !== 'filter'"
      :disabled="disabled"
    >
    </el-date-picker>
    <!-- 日期时间 -->
    <el-date-picker
      v-model="editValue"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      :format="datetimeFormat"
      :placeholder="$t('vue_label_commonobject_selectdatatime')"
      v-else-if="inputType === 'datetime' && originType !== 'filter'"
      :disabled="disabled"
    >
    </el-date-picker>
    <!-- 时间 -->
    <el-time-select
      v-model="editValue"
      value-format="HH:mm:ss"
      :format="timeFormat"
      :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
      :placeholder="$t('vue_label_commonobject_anytime')"
      v-else-if="inputType === 'time'"
      :disabled="disabled"
    >
    </el-time-select>
    <!-- 自定义时间组件-过滤器中的时间选择 -->
    <TimePerson
      v-model="editValue"
      v-else-if="
        (inputType === 'date' || inputType === 'datetime') &&
        originType === 'filter'
      "
      :inputType="inputType"
      :editValue="valueData.value"
      @getChartsByCondition="getChartsByCondition"
    >
    </TimePerson>
    <!-- 下拉框 -->
    <el-select
      v-model="editValue"
      filterable
      :clearable="
        value !== undefined &&
        value.value ==
          ('0%' ||
            '10%' ||
            '20%' ||
            '30%' ||
            '40%' ||
            '50%' ||
            '60%' ||
            '70%' ||
            '80%' ||
            '90%' ||
            '100%')
          ? false
          : true
      "
      :multiple="inputType === 'multi-select'"
      :placeholder="$t('label.select.please')"
      v-else-if="inputType === 'select' || inputType === 'multi-select'"
      @visible-change="clk($event)"
      :allow-create="originType == 'filter' ? true : false"
      :class="originType == 'filter' ? 'filtetClass' : ''"
      :loading="selectloading"
      :disabled="disabled"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value ? item.value : item.codevalue"
      >
      </el-option>
    </el-select>
    <!-- 查找/查找多选字段 -->
    <el-select
      v-else-if="
        (inputType === 'remote-select' ||
          inputType === 'remote-multi-select') &&
        fieldName !== 'tax' &&
        fieldName !== 'taxamount'
      "
      v-model="editValue"
      filterable
      :multiple="inputType === 'remote-multi-select' ? true : false"
      :remote-method="
        (query) =>
          originType === 'filter'
            ? filterRemoteMethod(query)
            : remoteMethod(query)
      "
      remote
      class="no-suffix rowheight"
      style="width: 100%"
      @blur="originType == 'filter' ? selectBlur($event) : ''"
      :disabled="disabled"
    >
      <p class="searchTipBox">
        <span @click="remoteSearch" style="display: inline-block; width: 100%">
          <i class="el-icon-search"></i>
          <span style="padding-left: 10px">
            <!-- 前往精准搜索 -->
            {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
          </span>
        </span>
      </p>
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        @click.native="originType === 'filter' ? optionsClick(item) : ''"
      >
      </el-option>
      <el-option
        class="display-none"
        key="new"
        :label="$t('label.new')"
        :value="$t('label.new')"
      >
      </el-option>
    </el-select>
    <span
      v-if="
        (inputType === 'remote-select' ||
          inputType === 'remote-multi-select') &&
        fieldName !== 'tax' &&
        fieldName !== 'taxamount'
      "
      :class="originType === 'table' ? 'remoteBtn' : 'remoteBtn1'"
      @click="remoteSearch"
    >
      <!-- [查找] -->
      <i class="el-icon-search"></i>
    </span>
    <!-- 标签 -->
    <div v-else-if="inputType === 'tag'" style="text-align: left">
      <el-tag
        v-for="tag in editValue"
        :key="tag.name"
        closable
        :type="
          tag.color === 'lan'
            ? ''
            : tag.color === 'hong'
            ? 'danger'
            : tag.color === 'lv'
            ? 'success'
            : tag.color === 'hui'
            ? 'info'
            : tag.color === 'huang'
            ? 'warning'
            : ''
        "
        effect="dark"
        class="tag"
        >{{ tag.name }}</el-tag
      >
    </div>
    <!-- 过滤器地址字段 -->
    <el-input
      v-model="editValue"
      v-if="inputType === 'address' && originType === 'filter'"
      :disabled="disabled"
    >
    </el-input>
    <!-- 地址字段: 包含邮编、国家或地区、省/辖区、城市/城镇、街道 -->
    <div class="address-box" v-else-if="inputType === 'address'">
      <label for>
        <!-- 邮编 -->
        {{ $t("System_AddressFieldType_Post") }}
        :
      </label>
      <el-input v-model="editValue" clearable></el-input>
      <label for>
        <!-- 国家或地区: -->
        {{ $t("label.address.country") }}
      </label>
      <!-- 请选择 -->
      <el-select
        v-model="editValue"
        clearable
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
          v-if="nationOptions.length > 0"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <label for>
        <!-- 省/辖区: -->
        {{ $t("label.address.province") }}
      </label>
      <!-- 请选择 -->
      <el-select
        clearable
        v-model="editValue"
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
         v-if="provinceOptions.length > 0"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <label for>
        <!-- 城市/城镇: -->
        {{ $t("label.address.city") }}
      </label>
      <!-- 请选择 -->
      <el-select
        v-model="editValue"
        clearable
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
          v-if="cityOptions.length > 0"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <label for>
        <!-- 街道: -->
        {{ $t("label.address.street") }}
      </label>
      <el-input v-model="editValue" clearable :disabled="disabled"></el-input>
    </div>
    <!-- 复选框 -->
    <el-checkbox
      v-model="editValue"
      v-else-if="inputType === 'checkbox'"
      :disabled="disabled"
    >
    </el-checkbox>
    <!-- 过滤器富文本字段 -->
    <el-input
      clearable
      v-model="editValue"
      type="textarea"
      :rows="rows"
      v-if="inputType === 'kindeditor' && originType === 'filter'"
      :disabled="disabled"
    />
    <!-- 过滤器文件字段 -->
    <el-input
      v-model="editValue"
      v-if="inputType === 'file' && originType === 'filter'"
      :disabled="disabled"
    >
    </el-input>
    <!-- 错误信息提示 -->
    <div class="errTip" v-if="showTip">
      {{ errTip }}
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="serchTableShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <listTable
        ref="listTable"
        :tableAttr="tableAttr"
        :tableData="tableData"
        @clickTax="clickTax"
      ></listTable>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @description: 内联编辑 编辑框
 * 功能：
 * 1、根据不同字段类型展示不同字段编辑样式
 * 2、错误信息提示
 * 逻辑：
 * 1、根据传入字段类型，支持展示普通文本、过滤器地理定位字段、折扣选择对象、税率对象选择、数字、评分、文本区、日期、
 * 日期时间、时间、自定义时间组件、下拉款、查找/查找多选、标签、过滤器地址字段、地址字段、复选框、
 * 过滤器富文本字段
 * 2、编辑后对数据进行规制校验，校验不通过则显示传入的提示信息
 */
import * as CommonObjApi from "./api.js";
import TimePerson from "@/components/TimePerson/index";
import * as Time from "@/utils/time";
import listTable from "@/views/systemSettings/components/marketSetting/listTable.vue";

export default {
  name: "editable-cell",
  props: {
    // 下拉接口loading
    selectloading: Boolean,
    tipShow: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    /**
     * 税率
     */
    taxValue: {
      type: Object,
      default: () => {},
    },
    /**
     * 字段编辑权限
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 输入框占位符
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * 字段所在行下标 批量操作时用
     */
    lineIndex: {
      type: Number,
      default: 0,
    },
    /**
     * 主从录入时定位 从对象位置
     */
    subordinateIndex: {
      type: Number,
      default: -1,
    },
    /**
     * 组件宽度
     */
    width: {
      type: String,
      default: "100%",
    },
    /**
     * 字段值 因类型不固定 用对象形式传递 value:{value:''}
     */
    value: {
      type: [Object,String],
      default: () => {
        return {value:''}
      },
    },
    /**
     * 字段label
     */
    field: {
      type: String,
      default: "",
    },
    /**
     * 字段名称
     */
    fieldName: {
      type: String,
    },
    /**
     * 字段id
     */
    fieldId: {
      type: String,
    },
    /**
     * 文本区字段行数
     */
    rows: {
      type: Number,
      default: 1,
    },
    /**
     * 字段类型
     */
    inputType: {
      type: String,
      default: "input",
    },
    /**
     * 评分字段最大值
     */
    schemefieldLength: {
      type: Number,
    },
    /**
     *  数字小数点位数
     */
    precision: {
      type: Number,
      default: 0,
    },
    /**
     * 数字最小值
     */
    min: {
      type: Number,
      default: 0 - Math.pow(10, 18) + 1,
    },
    /**
     * 数字最大值
     */
    max: {
      type: Number,
      default: Math.pow(10, 18) - 1,
    },
    /**
     * 下拉框选项
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 国家/地区选项
     */
    nationOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 省/辖区选项
     */
    provinceOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 城市/城镇选项
     */
    cityOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 控制错误信息显隐
     */
    showTip: {
      type: Boolean,
      default: false,
    },
    /**
     * 错误信息
     */
    errTip: {
      type: String,
      //   default: "错误信息..."
      default() {
        return this.$i18n.t("label.vlidaterule.message");
      },
    },
    /**
     * 下拉框远程搜索方法
     */
    remote: {
      type: String,
      default: "",
    },
    /**
     * 字段绑定动态规则事件
     */
    changeEvents: {
      type: String || Array,
    },
    /**
     * 父组件类型 table 表格 filter 过滤器
     */
    originType: {
      type: String,
      default: "",
    },
    /**
     * 筛选器所选字段对象
     */
    objid: {
      type: String,
    },
    /**
     * 批量操作记录字段所在对象api,父组件使用
     */
    objectApi: {
      type: String,
      default: "",
    },
    /**
     * 字段是否必填，父组件使用
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * 价格手册id
     */
    productid: {
      type: String,
      default: "",
    },
    /**
     * 父组件状态
     */
    editFlg: {
      type: String,
      default: "",
    },
  },
  components: {
    listTable,
    TimePerson,
  },
  data() {
    return {
      tableData: [],
      tableAttr: [
        {
          aa: "价格手册名称",
          bb: "pricebookname",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "产品名称",
          bb: "productname",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "价目表价格",
          bb: "listprice",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "已启用",
          bb: "isenable",
          cc: false,
          dd: true,
          ee: "F",
          width: "",
        },
      ],
      serchTableShow: false,
      radioData: {
        radio: "1",
        percentageValue: 0,
        cutValue: 0,
      }, // 折扣对象 折扣信息
      starsClassName: this.fieldName + this.lineIndex, // 评分字段通过字段和所在位置区分
      editValue: this.value && this.value.value ? this.value.value : "",
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")), // 日期格式化
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")), // 日期时间格式化
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")), // 时间格式化
      valueData:this.value
    };
  },
  created() {
    this.setInputValue();
    this.listenStorage();
    if (this.editFlg !== "EDIT") {
      //设置默认值
      if (
        ["shuliang", "quotedquantity", "qty", "quantity"].includes(
          this.fieldName
        )
      ) {
        this.$emit("editValueChangeTax", 1, this.fieldName, this.lineIndex);
      }
      if (
        [
          "discountamount",
          "tax",
          "taxamount",
          "rate",
          "unitprice",
          "xsjg",
          "listprice",
        ].includes(this.fieldName)
      ) {
        this.$emit("editValueChangeTax", 0.0, this.fieldName, this.lineIndex);
      }
    }
  },
  beforeDestroy(){
    window.removeEventListener("storage", this.storageChangeCallback);
  },
  methods: {
    switchShow(flg) {
      this.$emit("switchShow", this.lineIndex, flg);
    },
    /**
     * 计算税率
     */
    clickTax(row) {
      this.serchTableShow = false;
      this.editValue = row.listprice;
      this.$emit(
        "editValueChangeTax",
        row.listprice,
        this.fieldName,
        this.lineIndex
      );
    },
    serchTableShowMe() {
      if (this.taxValue.productid) {
        CommonObjApi.relatedProductPrice({
          productid: this.taxValue.productid,
          currency: this.$store.state.userInfoObj.currencyCode,
          isdefault: "0",
        }).then((res) => {
          this.tableData = res.data;
        });
        this.serchTableShow = true;
      } else {
        this.$message.error("请选择产品后查找价格手册");
      }
    },

    discoundSave(type) {
      if (type === 0) {
        let text = "";
        if (this.radioData.radio === "1") {
          if (this.taxValue.xiaoji) {
            text = Number(
              this.taxValue.xiaoji.value *
                (this.radioData.percentageValue * 0.01)
            ).toFixed(2);
          } else {
            text = Number(
              this.taxValue.amount.value *
                (this.radioData.percentageValue * 0.01)
            ).toFixed(2);
          }

          this.$emit(
            "editValueChangeTax",
            text,
            this.fieldName,
            this.lineIndex,
            true
          );
        } else {
          text = Number(this.radioData.cutValue).toFixed(2);
          this.$emit(
            "editValueChangeTax",
            text,
            this.fieldName,
            this.lineIndex,
            false
          );
        }
        this.$emit("editValueZhekouChange", this.radioData, this.lineIndex);
        this.switchShow(true);
      } else {
        let number = 0;
        if (this.taxValue.taxCheckList !== [] && this.taxValue.taxCheckList) {
          this.taxValue.taxCheckList.forEach((item) => {
            if (item.checked) {
              number += item.ratio * 0.01;
            }
          });
        }
        let text = "";
        if (this.taxValue.xiaoji) {
          text = Number(this.taxValue.xiaoji.value * number).toFixed(2);
        } else {
          text = Number(this.taxValue.amount.value * number).toFixed(2);
        }
        this.$emit("editValueChangeTax", text, this.fieldName, this.lineIndex);
        this.switchShow(false);
      }
    },
    /**
     * 处理字段显示值
     */
    setInputValue() {
      if (this.inputType && this.inputType === "number") {
        if (
          this.value.value === "" ||
          this.value.value === null ||
          this.value.value === undefined
        ) {
          this.editValue = undefined;
        } else {
          this.editValue = Number(this.value.value);
        }
      } else if (this.inputType === "percent-number") {
        if (this.value.value && this.value.value.split("%")[0] !== null) {
          this.editValue = Number(
            this.value.value.split("%")[0].replace(/,/g, "")
          );
        } else if (
          this.value.value === "" ||
          this.value.value === null ||
          this.value.value === undefined
        ) {
          this.editValue = undefined;
        }
      } else if (this.inputType === "checkbox") {
        if (this.value.value === "true") {
          this.valueData.value = true;
        } else {
          this.valueData.value = false;
        }
      } else {
        if (this.value !== undefined && this.value !== null) {
          this.editValue = this.value.value;
        }
      }
    },
    /**
     * 评分字段的星星鼠标悬浮和离开事件
     * @param {nub} 评分星星所在位置
     */
    setStar(nub) {
      this.stars = document.querySelectorAll(
        `.${this.fieldName}${this.lineIndex} span`
      );

      if (nub === "active") {
        nub = Number(this.editValue);
      }
      let name = "";
      name = "show2";
      for (var i = 0; i < this.stars.length; i++) {
        // 区分鼠标悬浮和鼠标离开
        this.stars[i].className = i <= nub ? name : "";
      }
    },
    /**
     * 设置评分字段的值
     * @param {nub} 评分星星所在位置
     */
    setClick(idx) {
      this.editValue = idx;
    },
    clk(e) {
      if (e) {
        this.$emit("selectes");
      }
    },
    /**
     * 输入框enter事件
     */
    searchEnterBtn() {
      this.$emit("searchEnterBtn", this.editValue);
    },
    /**
     * 监听查找字段新增事件
     */
    listenStorage() {
      window.addEventListener("storage", this.storageChangeCallback);
    },
    storageChangeCallback(event){
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        let option = {
          value: localStorage.getItem("relevantObjectDataId"),
          label: localStorage.getItem("relevantObjectDataName"),
        };
        this.$emit(
          "addFieldOption",
          localStorage.getItem("relevantObjectApi"),
          option
        );
      }
    },
    // 远程搜索方法
    remoteMethod(query) {
      this.$emit(this.remote, query);
    },
    // 查找/查找多选
    remoteSearch() {
      this.$emit("remoteSearch", this.editValue);
    },
    /**
     * 自定义时间组件 选择自定义时间时赋值
     * @param {val} 自定义时间的值
     */
    getChartsByCondition(val) {
      this.editValue = val;
    },
    /**
     * 查找多选字段失去焦点时赋值
     */
    selectBlur(e) {
      this.editValue = e.target.value;
    },
    /**
     * 查找多选字段失去焦点时赋值
     * @param {query} 查询条件
     */
    filterRemoteMethod(query) {
      this.$emit("remoteMethods", query, this.fieldId, this.objid);
    },
    /**
     * 筛选器中的下拉框选项点击事件
     * @param {val} 选项信息,包含lable和value
     */
    optionsClick(val) {
      //筛选器赋值不能赋id只能赋label值
      this.editValue = val.label;
    },
  },
  watch: {
    value: {
      handler() {
        if (this.inputType === "number") {
          this.editValue =
            this.value.value === undefined
              ? this.value.value
              : Number(this.value.value);
        } else if (this.inputType === "percent-number") {
          if (
            this.value.value &&
            this.value.value.toString().split("%")[0] !== null
          ) {
            this.editValue = Number(
              this.value.value.toString().split("%")[0].replace(/,/g, "")
            );
          } else {
            this.editValue = 0;
          }
        } else if (this.inputType === "checkbox") {
          if (this.value.value === "true" || this.value.value === true) {
            this.editValue = true;
          } else {
            this.editValue = false;
          }
        } else {
          this.editValue = this.value.value;
        }
      },
      deep: true,
    },
    editValue(nval) {
      this.$emit(
        "editValueChange",
        nval,
        this.lineIndex,
        this.fieldName,
        this.subordinateIndex
      );
    },
    inputType() {
      this.setInputValue();
    },
  },
};
</script>

<style lang="scss" scoped>
.xsjg {
  cursor: pointer;
}
.cellInput {
  position: relative;
}
.cellCard {
  position: absolute;
  top: 32px;
  z-index: 999;
  width: 260px;
  .discountCardInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    p {
      display: inline-block;
      width: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 14px;
      white-space: nowrap;
    }
    ::v-deep .el-input__inner {
      height: 25px;
      width: 100%;
    }
  }
  .btBox {
    margin-top: 20px;
  }
}
// 评分字段显示
.stars {
  width: 100%;
  height: 100%;
  overflow: auto;
  list-style: none;
  margin: 0;
  line-height: 25px;
  color: #ccc;
  // margin-left: 30px;
  float: left;
}

.stars span {
  font-size: 25px;
  margin-left: 5px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  right: 20px;
  .el-icon-arrow-up {
    display: none;
  }
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}

::v-deep .no-suffix {
  .el-input__inner {
    cursor: text;
  }
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 47px;
}
// ::v-deep .el-input__suffix-inner{
//   background-color: #ffffff;
// }
// ::v-deep .el-input__suffix-inner .el-input__icon {
//   line-height: 40px;
// }
.remoteBtn {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  position: absolute;
  right: 2%;
  margin-top: 1px;
  color: #c0c4cc;
  z-index: 100;
}

.remoteBtn1 {
  cursor: pointer;
  position: absolute;
  right: 7px;
  color: #c0c4cc;
  z-index: 100;
  top: 10px;
  font-size: 14px;
}

.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}

.editableCell {
  text-align: left;
  position: relative;
}

.errTip {
  color: red;
  height: 20px;
  text-align: left;
}
.editableCell ::v-deep .el-input-number {
  width: 100%;
  line-height: 0;
  .el-input__inner {
    text-align: left;
  }
}

.el-select {
  width: 100%;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
  // 解决图标错位
  display: flex;
  align-items: center;
}

.location-box {
  label {
    width: 50px;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
  }

  .el-input {
    width: calc(50% - 50px);
  }
}

.address-box {
  text-align: left;

  label {
    width: 80px;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
  }

  .el-input {
    width: calc(50% - 80px);
  }

  .el-select {
    width: calc(50% - 80px);
  }
}
::v-deep .location {
  display: flex;
  .el-input {
    margin-right: 5px;
  }
}

::v-deep .filtetClass,
::v-deep .rowheight {
  .el-input {
    .el-input__inner {
      height: 30px !important;
      overflow-x: auto;
    }
  }
}
.taxCheckBox {
  max-height: 140px;
  overflow: auto;
  .unLink {
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .taxList {
    height: 35px;
    .taxName {
      width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 13px;
      font-size: 13px;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow-x: scroll;
  max-width: 142.797px;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
