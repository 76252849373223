import axios from '@/config/httpConfig'
// 查询所选产品关联的价格手册条目价格
export function relatedProductPrice(data) {
    return axios.post('/master/relatedProductPrice', data)
  }
  //重置列宽
export function ResettingViewFieldMemory (data) {
    return axios.post('/view/list/resettingViewFieldMemory',data)
  }
// 潜在客户热度设置信息
export function getLeadHeat(data) {
    return axios.post('leadscore/getLeadHeat', data)
  }
  // 获取对象权限
export function getObjectPermission(data) {
    return axios.post('objectdetail/getObjectPermission', data)
  }
  // 获取视图字段设置
export function getFieldSetup(data) {
    return axios.post('/view/getFieldSetup', data)
  }
  // 更新列表字段宽度
export function updateViewFieldMemory(data) {
    return axios.post('view/list/updateViewFieldMemory', data)
  }
  //获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
  }
  // 更新视图列表记录
export function updateViewListAjax(data) {
    return axios.post('/view/list/updateViewListAjax', data)
  }
// 判断业务机会，报价单，订单，发票，采购订单是否有子产品
export function existRelatedProductInfo(data) {
  return axios.post('/master/existRelatedProductInfo', data)
}
// 删除业务机会，报价单，订单，发票，采购订单关联的子产品记录
export function deleteRelatedProductInfo(data) {
  return axios.post('/master/deleteRelatedProductInfo', data)
}
// 查询模板视图按钮状态
export function getTemplateViewStatus(data) {
  return axios.post('/cpq/template/getTemplateViewStatus', data)
}
// 获取要展示的接单/拒单按钮
export function oRButton(data) {
  return axios.post('/servicePartner/oRButton', data)
}
// 接单
export function utOrderReceive(data) {
  return axios.post('/servicePartner/utOrderReceive', data)
}
// 拒单
export function utOrderReject(data) {
  return axios.post('/servicePartner/utOrderReject', data)
}
// 拒单原因
export function getRejectReason() {
  return axios.post('/servicePartner/getRejectReason')
}
// 选项列表内联编辑查询下拉项接口
export function getSelectValue(data) {
  return axios.post('/view/list/getSelectValue', data)
}
